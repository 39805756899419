import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getMe } from '../api/private-v1-users'
import { errorHandler } from '../common/errorHandler'

export const unauthenticatedUser = {
    username: null,
    is_developer: false,
    is_staff: false,
    settings: {},
    available_pipelines: []
}

export const AuthContext = React.createContext({
    user: unauthenticatedUser,
    setUser: (newUser) => {}
})

export function getAuthContextUser() {
    return Object.assign({}, unauthenticatedUser)
}

export async function checkAuthContextAndGetActual() {
    try {
        const response = await getMe()
        if (response.status === 401) {
            return unauthenticatedUser
        }
        const { username, is_developer, is_staff, settings, available_pipelines } = await response.json()
        return {
            username,
            is_developer,
            is_staff,
            settings,
            available_pipelines
        }
    } catch (error) {
        errorHandler(error)
        return unauthenticatedUser
    }
}

export async function saveAuthContext() {
    return await checkAuthContextAndGetActual()
}

export function OnlyAuthenticatedRoute({ component: Component, ...rest }) {
    const auth = useContext(AuthContext)
    return <Route {...rest} render={(props) => (auth.user.username ? <Component {...props} /> : <Redirect to='/sign-in' />)} />
}

export function OnlyUnauthenticatedRoute({ component: Component, ...rest }) {
    const auth = useContext(AuthContext)
    return <Route {...rest} render={(props) => (!auth.user.username ? <Component {...props} /> : <Redirect to='/app' />)} />
}
