import { API_URL } from '../config'
import { getResponseJson } from './common'

export async function getVersion() {
    const response = await fetch(`${API_URL}/api/private/v1/version/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
    return getResponseJson(response)
}
